import { FC, useEffect, useState } from 'react';
import { alpha, styled, SxProps } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { Bid } from 'shared/types/models';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NextLink } from 'components/atoms/NextLink';
import AuctionSummary from 'components/AuctionSummary';
import Box from '@mui/material/Box';
import { getClientStorage, LOCAL_STORAGE_TYPE } from 'utils/ClientStorage';
import MD5 from 'crypto-js/md5';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';

const NOTIFICATIONS_STORAGE_KEY = 'notifications';
const localStorage = getClientStorage(LOCAL_STORAGE_TYPE);

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.down(1160)]: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '&.MuiBadge-root': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& .MuiBadge-badge': {
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  },
}));

const StyledIcon = styled(StyledFontAwesomeIconMui5)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledLink = styled(NextLink)(({ theme }) => ({
  textDecoration: 'none',
  margin: '0 2px',
  padding: '6px 8px',
  color: theme.palette.text.primary,
  '&:visited': {
    color: theme.palette.text.primary,
  },
  '&:hover': {
    textDecoration: 'none',
    color: alpha(theme.palette.common.black, 0.7),
  },
  cursor: 'pointer',
  '&.active': {
    fontWeight: 600,
  },
  '&.highlighted': {
    border: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    borderRadius: '3px',
  },
}));

const bellIconButtonStyles: SxProps<ThemeMui5> = () => ({
  '&.MuiIconButton-root': {
    height: '34px',
    width: '34px',
  },
});

type NotificationsProps = {
  bids: Bid[];
  title?: string;
};

const Notifications: FC<NotificationsProps> = ({
  bids = [],
  title = 'My Vehicles',
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    const hash = generateHash(bids);
    localStorage.set(NOTIFICATIONS_STORAGE_KEY, hash);
    setAnchorEl(event.currentTarget);
    setHasNewNotifications(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const generateHash = (bids: Bid[]): string => {
    return MD5(bids.map((bid) => bid.id).join()).toString();
  };

  useEffect(() => {
    const storedHash = localStorage.get(NOTIFICATIONS_STORAGE_KEY);
    if (storedHash) {
      const hash = generateHash(bids);
      if (storedHash === hash) {
        return;
      }
    }
    setHasNewNotifications(true);
  }, [bids]);

  return (
    <Container>
      <StyledBadge
        color='secondary'
        variant='dot'
        invisible={!hasNewNotifications}
      >
        <IconButton
          sx={bellIconButtonStyles}
          onClick={(e) => handleClick(e)}
          edge='start'
        >
          <StyledIcon icon={faBell} size={'xs'} />
        </IconButton>
      </StyledBadge>
      <Menu
        open={open}
        anchorEl={anchorEl}
        keepMounted
        disableScrollLock
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -15,
          horizontal: 0,
        }}
      >
        <Box px={3} py={1}>
          <Typography variant='h6'>{title}</Typography>
        </Box>
        {bids.map((bid) => (
          <MenuItem key={bid.id}>
            <StyledLink href={`/deals/${bid.auction?.id}`}>
              <AuctionSummary
                image={bid.auction.offer.images[0]['@href']}
                auctionId={bid.auction.id}
                make={bid.auction.offer.make.name}
                model={bid.auction.offer.model.name}
                trim={bid.auction.offer.trim?.name}
                year={bid.auction.offer.year}
                msrp={bid.auction.msrp}
                disableLink
              />
            </StyledLink>
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

export default Notifications;
