import TextField, { TextFieldProps } from '@mui/material/TextField';
import Rest from 'services/rest';
import { FC, useState } from 'react';
import { lightThemeMui5 } from 'theme';
import { isEmail } from 'utils/validators';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import { useScreenSize } from 'hooks/useScreenSize';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import { getNextjsImageSrc } from 'utils/functions';

const rest = new Rest();

export const SubscriberForm: FC = ({
  InputProps = {},
  FormHelperTextProps = {},
}: TextFieldProps) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(undefined);
  const [open, setOpen] = useState(false);
  const { isExtraSmallScreen, isSmallScreen, isMediumScreen } = useScreenSize();

  const image =
    isExtraSmallScreen || isSmallScreen
      ? '/img/subscriber/background_mobile.webp'
      : '/img/subscriber/desktop_background.webp';
  const imageWidth = isExtraSmallScreen || isSmallScreen ? '384' : '1280';
  const bottom = '/img/subscriber/bottom.webp';
  const top =
    isExtraSmallScreen || isSmallScreen
      ? '/img/subscriber/top_mobile.webp'
      : '/img/subscriber/top.webp';
  const buttonBorder = '/img/subscriber/button_border.webp';

  const bg = getNextjsImageSrc(image, imageWidth, 100);

  const BottomWidget = styled(Image)(() => ({
    position: 'absolute',
    top: '100%',
    left: '0%',
    transform: 'translate(-65%, -75%)',
  }));

  const TopWidget = styled(Image)(() => ({
    position: 'absolute',
    top: '0',
    right: '0',
    transform:
      isExtraSmallScreen || isSmallScreen
        ? 'translate(10%, -30%)'
        : 'translate(40%, -40%)',
  }));

  const ButtonBorder = styled(Image)(() => ({
    position: 'absolute',
    top: '0%',
    left: '0%',
    transform: 'translate(-29%, -10%)',
  }));

  const handleClose = () => {
    setOpen(false);
    setEmail('');
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (isEmail(e.target.value)) {
      setEmailError(undefined);
    } else {
      setEmailError('Invalid email format');
    }
  };
  async function submit() {
    try {
      if (!email) {
        setEmailError('Invalid email format');
        return;
      }
      if (!emailError) {
        await rest.addEmailToMailingList(email);
        setOpen(true);
      }
    } catch (error) {
      setEmailError(error.errors[0].msg);
    }
  }
  const buildInputProps = ({ InputProps, theme }) => ({
    ...InputProps,
    disableUnderline: true,
    sx: {
      borderRadius: 7,
      boxShadow: `0px 2px 3px rgba(0, 0, 0, 0.2)`,
      height: '50px',
      paddingRight: 0,
      background: theme.palette.common.white,
      '& input': {
        padding: 4,
        color: theme.palette.common.black,
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.87)',
          fontStyle: 'italic',
          fontWeight: '400',
          textTransform: 'uppercase',
        },
      },
    },
  });

  const buildFormHelperTextProps = ({ FormHelperTextProps, theme }) => ({
    ...FormHelperTextProps,
    sx: { margin: theme.spacing(0.5, 4) },
  });
  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        marginTop: 1,
        position: 'relative',
        paddingBlock: 4,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: isExtraSmallScreen || isSmallScreen ? '600px' : '360px',
        backgroundPosition: '70% 100%',
      }}
    >
      <BottomWidget
        src={bottom}
        alt='bottom widget'
        height={isExtraSmallScreen || isSmallScreen ? 169 : 203}
        width={isExtraSmallScreen || isSmallScreen ? 203 : 259}
      />
      <TopWidget
        src={top}
        height={isExtraSmallScreen || isSmallScreen ? 230 : 332}
        width={isExtraSmallScreen || isSmallScreen ? 230 : 431}
        alt='top widget'
      />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            background: 'white',
            color: 'primary.main',
          },
          '& .MuiDialogContentText-root': {
            color: 'primary.main',
          },
        }}
      >
        <DialogTitle>You were subscribed successfully!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please, check your email <b>{email}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          width: isExtraSmallScreen || isSmallScreen ? '100%' : '70%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent:
            isExtraSmallScreen || isSmallScreen || isMediumScreen
              ? 'normal'
              : 'space-between',
          alignItems: isExtraSmallScreen || isSmallScreen ? 'center' : 'normal',
          paddingInline: 4,
          gap: isSmallScreen ? 4 : 2,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography fontWeight={'bold'} variant='h4'>
            Ready to save up to $20K on your next car deal?
          </Typography>
          <Typography
            variant='body1'
            sx={{ width: isExtraSmallScreen || isSmallScreen ? '100%' : '75%' }}
          >
            Subscribe to our newsletter and get our FREE guide—packed with
            exclusive lease deals and expert money-saving tips. Start saving BIG
            today!
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: isExtraSmallScreen || isSmallScreen ? '100%' : '65%',
            gap: isMediumScreen ? 0 : 0.75,
            alignItems: 'center',
          }}
        >
          <TextField
            error={emailError}
            sx={{
              width: isSmallScreen || isExtraSmallScreen ? '100%' : '90%',
            }}
            value={email}
            onChange={handleEmailChange}
            helperText={emailError ? emailError : ' '}
            placeholder='Your email'
            size='medium'
            variant='standard'
            InputProps={buildInputProps({ InputProps, theme: lightThemeMui5 })}
            FormHelperTextProps={buildFormHelperTextProps({
              FormHelperTextProps,
              theme: lightThemeMui5,
            })}
          />
          <Box sx={{ position: 'relative' }}>
            <ButtonBorder
              src={buttonBorder}
              hidden={isExtraSmallScreen || isSmallScreen || isMediumScreen}
              width={430}
              alt='border button'
              height={58}
            />
            <StyledButtonMui5
              color='secondary'
              sx={{ width: '180px' }}
              onClick={() => {
                submit();
              }}
            >
              SUBSCRIBE NOW
            </StyledButtonMui5>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
