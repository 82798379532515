import { useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { lightThemeMui5 } from '../../theme';
import Typography from '@mui/material/Typography';
import { isEmail } from 'utils/validators';
import Rest from 'services/rest';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const rest = new Rest();

export const SubscribeField = ({
  InputProps = {},
}: TextFieldProps): JSX.Element => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(undefined);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setEmail('');
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (isEmail(e.target.value)) {
      setEmailError(undefined);
    } else {
      setEmailError('Invalid email format');
    }
  };

  async function submit() {
    try {
      if (!email) {
        setEmailError('Invalid email format');
        return;
      }
      if (!emailError) {
        await rest.addEmailToMailingList(email);
        setOpen(true);
      }
    } catch (error) {
      setEmailError(error.errors[0].msg);
    }
  }

  const buildInputProps = ({ InputProps, theme }) => ({
    ...InputProps,
    disableUnderline: true,
    endAdornment: (
      <InputAdornment position='end'>
        <Button
          onClick={submit}
          disabled={emailError}
          sx={{
            background: theme.palette.secondary.main,
            color: 'white',
            paddingInline: 2,
            borderRadius: 1,
            height: '50px',
            ':hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:disabled': {
              cursor: 'not-allowed',
              backgroundColor: 'lightgray',
              color: 'black',
            },
          }}
        >
          <Typography>Subscribe</Typography>
        </Button>
      </InputAdornment>
    ),
    sx: {
      borderRadius: 1,
      height: '50px',
      paddingRight: 0,
      background: theme.palette.common.white,
      '& input': {
        padding: theme.spacing(2, 1, 2, 2),
        color: theme.palette.common.black,
        '&::placeholder': {
          color: theme.palette.common.black,
          opacity: 0.87,
        },
      },
    },
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            background: 'white',
            color: 'primary.main',
          },
          '& .MuiDialogContentText-root': {
            color: 'primary.main',
          },
        }}
      >
        <DialogTitle>You were subscribed successfully!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please, check your email <b>{email}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
      <TextField
        error={emailError}
        value={email}
        onChange={handleEmailChange}
        helperText={emailError ? emailError : ''}
        placeholder='Your email'
        size='medium'
        variant='standard'
        InputProps={buildInputProps({ InputProps, theme: lightThemeMui5 })}
      />
    </>
  );
};
