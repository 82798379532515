import Alert from '@mui/material/Alert';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  donateBannerOpenStateAtom,
  redirectBannerOpenStateAtom,
} from 'recoil/atoms';
import Link from '@mui/material/Link';
import NextLinkMui5 from '../../components/atoms/NextLink';
import { green } from 'theme/colors';

const BannerAlert = styled(Alert)(
  ({ theme }) => `
  &.MuiAlert-root {
    padding: ${theme.spacing(0, 2)};
    border-radius: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    & .MuiAlert-icon {
      display: none;
    }
    & .MuiAlert-message {
      font-weight: bold;
      margin: 0 auto;
      width: 100%;
      text-align: center;
    }
  }`,
);

type BannerProps = {
  background?: string;
  hidden?: boolean;
};

export const DonateBanner: FC<BannerProps> = ({
  background = green[900],
  hidden = false,
}) => {
  const [isOpened, setIsOpened] = useRecoilState(donateBannerOpenStateAtom);
  const isRedirectBannerOpened = useRecoilValue(redirectBannerOpenStateAtom);

  return (
    <>
      {!hidden && !isRedirectBannerOpened && isOpened && (
        <BannerAlert
          variant='filled'
          style={{ background }}
          onClose={() => {
            setIsOpened(false);
          }}
        >
          <b>
            Drive Forward with Purpose 🌟 Check out MLK Day Super Deals{' '}
            <Link
              component={NextLinkMui5}
              sx={{ color: 'white', textDecorationColor: 'white' }}
              href='/deals?sortType=best_deal'
            >
              HERE
            </Link>
          </b>
        </BannerAlert>
      )}
    </>
  );
};
