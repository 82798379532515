import { styled as styledMui5 } from '@mui/material/styles';
import Image from 'next/image';
import { FC, useState } from 'react';
import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';
import { AuctionFromSearch } from 'shared/types/api/getAuctions';
import { getOfferImage } from 'shared/utils/functions';
import { getNextjsImageSrc } from 'utils/functions';

type Props = {
  auction: AuctionFromSearch;
  ignoreProcessedImage?: boolean;
};

const ImageWrapper = styledMui5('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '75%',
}));

export const Container = styledMui5('div', { skipSx: false })<{
  isLoading: boolean;
}>(({ isLoading }) => ({
  display: isLoading ? 'flex' : 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Svg = styledMui5('svg')(() => ({
  '@keyframes svg-animation': {
    '0%': { transform: 'rotateZ(0deg)' },
    '100%': { transform: 'rotateZ(360deg)' },
  },
  position: 'absolute',
  maxWidth: '50px',
  animation: 'svg-animation 2s linear infinite',
}));

export const Circle = styledMui5('circle', { skipSx: false })(({ theme }) => ({
  '@keyframes circle-animation': {
    '0%': { strokeDashoffset: '280', transform: 'rotate(0)' },
    '25%': { strokeDashoffset: '280', transform: 'rotate(0)' },
    '50%': { strokeDashoffset: '75', transform: 'rotate(45deg)' },
    '75%': { strokeDashoffset: '75', transform: 'rotate(45deg)' },
    '100%': { strokeDashoffset: '280', transform: 'rotate(360deg)' },
  },
  display: 'block',
  fill: 'transparent',
  strokeLinecap: 'round',
  strokeDasharray: '283px',
  strokeDashoffset: '280px',
  transformOrigin: '50% 50%',
  stroke: theme.palette.primary.main,
  strokeWidth: '5px',
  animation: 'circle-animation 1.5s linear infinite',
}));

const spinnerLoadingAtom = atomFamily<boolean, number>({
  key: 'spinnerLoading',
  default: true,
});

const LoadingSpinner = ({ id }: { id: number }) => {
  const isLoading = useRecoilValue(spinnerLoadingAtom(id));

  return (
    <Container isLoading={isLoading}>
      <Svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
        <Circle cx='50' cy='50' r='45' />
      </Svg>
    </Container>
  );
};

function getImageSrc(originalSrc: string, fallbackDepth: number) {
  if (fallbackDepth === -1) {
    return getNextjsImageSrc(originalSrc, '320', 75);
  }

  if (fallbackDepth > 1) {
    return '/img/car.svg';
  }

  return originalSrc;
}

function isUnoptimized(fallbackDepth: number) {
  if (fallbackDepth === -1 || fallbackDepth === 1 || fallbackDepth === 3) {
    return true;
  }

  return false;
}

// Steps Strategy
// -1 - SMALL Original SRC + Manualy optimized
// 0 - Original SRC + Next JS optimized
// 1 - Original SRC + Unoptimized
// 2 - Fallback SRC + Next JS optimized
// 3 - Fallback SRC + Unoptimized

export const OfferCardMedia: FC<Props> = ({
  auction,
  ignoreProcessedImage,
}) => {
  const setIsLoading = useSetRecoilState(spinnerLoadingAtom(auction.id));
  const [step, setStep] = useState(-1);

  const title = `${auction?.offer?.make?.name} ${auction?.offer?.model?.name} ${
    auction?.offer?.style_display_name || 'Car image'
  }`;

  const isProcessedImage =
    Boolean(auction.offer.processed_image) && !ignoreProcessedImage;

  const src = getOfferImage({ offer: auction.offer, ignoreProcessedImage });

  return (
    <ImageWrapper>
      <Image
        src={getImageSrc(src, step)}
        style={{
          transform: isProcessedImage ? null : 'scale(0.92)',
        }}
        sizes='(max-width: 660px) 100vw, (max-width: 1280px) 68vw, 23vw'
        alt={title}
        title={title}
        quality={100}
        layout='fill'
        objectFit='contain'
        onLoadingComplete={() => {
          setIsLoading(false);
          if (step === -1) {
            setStep(0);
          }
        }}
        unoptimized={isUnoptimized(step)}
        onError={() => setStep((prev) => prev + 1)}
      />

      <LoadingSpinner id={auction.id} />
    </ImageWrapper>
  );
};

OfferCardMedia.displayName = 'OfferCardMedia';
