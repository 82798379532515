import Cookies from 'universal-cookie';

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  MFAOption,
} from 'amazon-cognito-identity-js';
import keys from 'lodash/keys';

import { convertPhoneNumberToE164 } from 'shared/utils/functions';
import CookieStorage from '../utils/cognito-cookie-storage';

export function removeCognitoCookies(): void {
  const cookies = new Cookies();
  const allCookies = cookies.getAll();
  const removeCookies = keys(allCookies).filter((cookieName) =>
    cookieName.startsWith('CognitoIdentityServiceProvider'),
  );
  removeCookies.forEach((name) => cookies.remove(name));
}

class Auth {
  constructor(headerCookie?: string) {
    this.storage = new CookieStorage({
      secure: false,
      headerCookie,
    });

    this.userPool = new CognitoUserPool({
      UserPoolId: process.env.COGNITO_USER_POOL_ID || null,
      ClientId: process.env.COGNITO_CLIENT_ID || null,
      Storage: this.storage,
    });

    this.cognitoUser = this.userPool.getCurrentUser();
  }

  storage;
  userPool: CognitoUserPool;
  cognitoUser: CognitoUser;

  sessionPromise: Promise<CognitoUserSession>;

  async getAccessToken(): Promise<string> {
    const session = await this.getSession();

    if (!session) {
      return;
    }

    return session.getAccessToken().getJwtToken();
  }

  async getSession(): Promise<CognitoUserSession> {
    if (!this.cognitoUser) {
      return;
    }

    // this is a workaround to avoid multiple identical parallel requests
    // to cognito in case access token has been expired and we trigger multiple REST requests at once
    if (!this.sessionPromise) {
      this.sessionPromise = new Promise((resolve) => {
        this.cognitoUser.getSession((err, session: CognitoUserSession) => {
          if (err) {
            return resolve(undefined);
          }

          return resolve(session);
        });
      });

      setTimeout(() => {
        this.sessionPromise = null;
      }, 5000);
    }

    return this.sessionPromise;
  }

  async isAuthenticated(): Promise<boolean> {
    const session = await this.getSession();

    if (!session) {
      return false;
    }

    return session.isValid();
  }

  async getEmailVerificationCode() {
    if (!(await this.isAuthenticated())) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.cognitoUser.getAttributeVerificationCode('email', {
        onSuccess: function () {
          resolve(true);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  }

  async verifyUserEmail(code: string): Promise<any> {
    if (!(await this.isAuthenticated())) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.cognitoUser.verifyAttribute('email', code, {
        onSuccess: function (result) {
          return resolve(result);
        },
        onFailure: function (err) {
          return reject(err);
        },
      });
    });
  }

  login(
    username: string,
    password: string,
    getCode: () => Promise<string>,
    newPassword?: string,
  ): Promise<CognitoUserSession> {
    this.cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
      Storage: this.storage,
    });

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      this.cognitoUser.authenticateUser(authDetails, {
        onSuccess: async (result) => {
          this.cognitoUser.getSession((err, session) => {
            if (err) {
              return;
            }
            this.cognitoUser.setSignInUserSession(session);
          });
          resolve(result);
        },
        onFailure: (err) => {
          return reject(err);
        },
        mfaRequired: async function () {
          // MFA is required to complete user authentication.
          // Get the code from user and call
          const code = await getCode();
          self.cognitoUser.sendMFACode(code, this);
        },

        newPasswordRequired: () => {
          this.cognitoUser.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: (result) => resolve(result),
              onFailure: (err) => reject(err),
            },
          );
        },
      });
    });
  }

  async confirmEmail(
    email: string,
    code: string,
    forceAliasCreation = true,
  ): Promise<any> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(
        code,
        forceAliasCreation,
        (err, result) => {
          if (err) {
            return reject(err);
          }
          return resolve(result);
        },
      );
    });
  }

  async resendConfirmationEmail(email: string): Promise<string> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode(function (err, result) {
        if (err) {
          return reject(err);
        }
        return resolve(result);
      });
    });
  }

  async getMFAOptions(): Promise<MFAOption[]> {
    if (!(await this.isAuthenticated())) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.cognitoUser.getMFAOptions((err, result = []) => {
        if (err) {
          return reject(err);
        }
        resolve(result);
      });
    });
  }

  async disableSMSMFA(): Promise<any> {
    if (!(await this.isAuthenticated())) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.cognitoUser.disableMFA((err) => {
        if (err) {
          return reject(err);
        }
        return this.setUserMfaPreference({
          PreferredMfa: false,
          Enabled: false,
        }).then((result) => resolve(result));
      });
    });
  }

  async enableSMSMFA(): Promise<void> {
    if (!(await this.isAuthenticated())) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.cognitoUser.enableMFA((err) => {
        if (err) {
          return reject(err);
        }
        return this.setUserMfaPreference({
          PreferredMfa: true,
          Enabled: true,
        }).then((result) => resolve(result));
      });
    });
  }

  async setUserMfaPreference(setting): Promise<any> {
    if (!(await this.isAuthenticated())) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.cognitoUser.setUserMfaPreference(
        setting,
        null,
        function (err, result) {
          if (err) {
            return reject(err);
          }
          return resolve(result);
        },
      );
    });
  }

  logout(): void {
    this.cognitoUser.signOut();
  }

  async changePassword({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }): Promise<any> {
    if (!(await this.isAuthenticated())) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.cognitoUser.changePassword(
        oldPassword,
        newPassword,
        function (err, result) {
          if (err) {
            return reject(err);
          }
          resolve(result);
        },
      );
    });
  }

  forgotPassword(username: string): Promise<any> {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (res) => {
          resolve(res);
        },

        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  resetPassword({
    code,
    password,
    username,
  }: {
    code: string;
    password: string;
    username: string;
  }): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, password, {
        onSuccess: () => {
          resolve();
        },

        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  isEmailAvailable = async (email: string): Promise<boolean> => {
    // adapted from @herri16's solution: https://github.com/aws-amplify/amplify-js/issues/1067#issuecomment-436492775
    try {
      // If "forceAliasCreation" set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
      // We need to send '000000' to fail the confirm registration result, but from this request we can detect if user signed in our users pool.
      await this.confirmEmail(email, '000000', false);
      // this should always throw an error of some kind, but if for some reason this succeeds then the user probably exists.
      return false;
    } catch (err) {
      switch (err.code) {
        case 'LimitExceededException':
          throw err;
        case 'UserNotFoundException':
          return true;
        case 'NotAuthorizedException':
        case 'AliasExistsException':
        case 'CodeMismatchException':
        case 'ExpiredCodeException':
          return false;
        default:
          return false;
      }
    }
  };

  async changePhoneNumber(phoneNumber: string): Promise<any> {
    const phoneAttribute = new CognitoUserAttribute({
      Name: 'phone_number',
      Value: convertPhoneNumberToE164(phoneNumber),
    });

    return new Promise((resolve, reject) => {
      this.cognitoUser.updateAttributes(
        [phoneAttribute],
        function (err, result) {
          if (err) {
            return reject(err);
          }
          resolve(result);
        },
      );
    });
  }

  async confirmNewPhoneNumber(code: string): Promise<string> {
    if (!(await this.isAuthenticated())) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.cognitoUser.verifyAttribute('phone_number', code, {
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),
      });
    });
  }

  async resendConfirmationSms(): Promise<void> {
    if (!(await this.isAuthenticated())) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.cognitoUser.getAttributeVerificationCode('phone_number', {
        onSuccess: () => resolve(),
        onFailure: (err) => reject(err),
      });
    });
  }

  async isPhoneNumberVerified(): Promise<boolean> {
    if (!(await this.isAuthenticated())) {
      return false;
    }

    return new Promise((resolve, reject) => {
      this.cognitoUser.getUserAttributes(function (err, attributes) {
        if (err) {
          return reject(err);
        }
        const phoneNumberVerifed = attributes.find(
          (attr) => attr.getName() === 'phone_number_verified',
        );

        resolve(phoneNumberVerifed?.getValue() === 'true');
      });
    });
  }

  async getPhoneNumber(): Promise<string> {
    if (!(await this.isAuthenticated())) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.cognitoUser.getUserAttributes(function (err, attributes) {
        if (err) {
          return reject(err);
        }
        const phoneNumber = attributes.find(
          (attr) => attr.getName() === 'phone_number',
        );

        resolve(phoneNumber?.getValue());
      });
    });
  }
}

export const auth = new Auth();

export default Auth;
