export function isEmail(value: string): boolean {
  const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  return re.test(value);
}

export function isWeakPassword(password: string): boolean {
  return !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(password);
}

export function isExists(value: string): boolean {
  return value !== '' && ![null, undefined].includes(value);
}

export function isMobilePhone(value: string): boolean {
  const re =
    /^(?:\+1|\+38)?\s?(\([0-9]{3}\)\s?|[0-9]{3}-)[0-9]{3}-[0-9]{4}$|^(?:\+34)?\s?(\([0-9]{3}\)\s?|[0-9]{3}-)[0-9]{3}$/;

  return re.test(value);
}

export function isSSN(value: string): boolean {
  const re = /^\d{3}-\d{2}-\d{4}$/;

  return re.test(value);
}

export function isVinValid(value: string): boolean {
  const re =
    /^(?<wmi>[A-HJ-NPR-Z\d]{3})(?<vds>[A-HJ-NPR-Z\d]{5})(?<check>[\dX])(?<vis>(?<year>[A-HJ-NPR-Z\d])(?<plant>[A-HJ-NPR-Z\d])(?<seq>[A-HJ-NPR-Z\d]{6}))$/;

  return re.test(value);
}

export const isValidDeliveryRange = (range: string): boolean => {
  return (
    range === null ||
    typeof range === 'undefined' ||
    (isExists(range) && Number.isFinite(Number(range)))
  );
};

export const isValidAutoImportFlag = (value: any): boolean =>
  typeof value === 'boolean' ||
  typeof value === 'undefined' ||
  Object.is(value, null);
