import { ImageProps } from 'next/image';
import {
  LogoType,
  ONE_DAY,
  ONE_HOUR,
  ONE_MINUTE,
  ONE_SECOND,
} from '../shared/utils/constants';

export const getPriceReduction = (a: number, b: number): number => {
  return ((a - b) * 100) / a;
};

export const moneyFormat = (val: number, round = true): string => {
  if (!val) {
    val = 0;
  }

  return (round ? Math.round(val) : val)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    .replace('.00', ''); // cut .00 in numbers like 1.00
};

const addZeroPrefix = (n: number): string | number => {
  return n < 10 ? '0' + n : n;
};

export const msToTime = (duration: number): string => {
  const s = addZeroPrefix(Math.floor((duration / ONE_SECOND) % 60));
  const m = addZeroPrefix(Math.floor((duration / ONE_MINUTE) % 60));
  const h = Math.floor((duration / ONE_HOUR) % 24);
  const d = Math.floor(duration / ONE_DAY);

  if (d >= 1) {
    // 1 day 31 minutes
    if (h === 0) {
      return `${d} ${d === 1 ? 'day' : 'days'} ${m} ${
        m === 1 ? 'minute' : 'minutes'
      }`;
    }
    // 1 day 14 hours
    return `${d} ${d === 1 ? 'day' : 'days'} ${h} ${
      h === 1 ? 'hour' : 'hours'
    }`;
  }
  // 03:45:13
  return `${addZeroPrefix(h)}h:${m}m:${s}s`;
};

export const msToTimeShort = (duration: number): string => {
  const s = addZeroPrefix(Math.floor((duration / ONE_SECOND) % 60));
  const m = addZeroPrefix(Math.floor((duration / ONE_MINUTE) % 60));

  // 03:45
  return `${m}:${s}`;
};

type Variants = {
  '0'?: string;
  '1'?: string;
  '>=1'?: string;
  '>1'?: string;
};

export const pluralFormat = (n: number, variants: Variants): string => {
  if (n === 0 && variants.hasOwnProperty('0')) {
    return variants['0'];
  }
  if (n === 1 && variants.hasOwnProperty('1')) {
    return variants['1'];
  }
  if (n > 1 && variants.hasOwnProperty('>1')) {
    return variants['>1'];
  }
  if (n >= 1 && variants.hasOwnProperty('>=1')) {
    return variants['>=1'];
  }
};

export const createSitemap = (urls: string[]) =>
  `<?xml version="1.0" encoding="UTF-8"?>
  <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
    ${urls.join('')}
  </urlset>`;

type ChangeFreq =
  | 'always'
  | 'hourly'
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'yearly'
  | 'never';

export const createSitemapUrl = (
  loc: string,
  priority: number,
  changefreq: ChangeFreq,
) => `
  <url>
    <loc>${loc}</loc>
    <priority>${priority}</priority>
    <changefreq>${changefreq}</changefreq>
  </url>`;

export const escapeWhitespace = (str: string) => str.replace(/\s/gi, '+');
export const encodeAmpersand = (url: string) => url.replace(/&/gi, '&amp;');

export enum UserAgentDeviceType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export const getDeviceType = (userAgent: string): UserAgentDeviceType =>
  userAgent.includes('Mobi')
    ? UserAgentDeviceType.MOBILE
    : UserAgentDeviceType.DESKTOP;

export const rem2px = (pixels: number) => pixels * 16;

/**
 * @param width - CHECK nextjs.config.js for configured available image sizes https://nextjs.org/docs/pages/api-reference/components/image#imagesizes
 * @param quality - 1 - 100. 100 is the best quality
 * */
export function getNextjsImageSrc(src: string, width: string, quality = 100) {
  return `/_next/image?url=${src}&w=${width}&q=${quality}`;
}

type URIComponentType = string | number | boolean;
export const getQueryString = (
  queryParams: Record<string, URIComponentType | URIComponentType[]>,
) =>
  Object.keys(queryParams).length > 0
    ? `?${Object.entries(queryParams)
        .map(([key, value]) => {
          return Array.isArray(value)
            ? value
                .map((item) => `${key}=${encodeURIComponent(item)}`)
                .join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&')}`
    : '';

export const getSiteLogoStyleProps = (
  type: LogoType = LogoType.Regular,
): Partial<ImageProps> => {
  if (type == LogoType.CyberMonday) {
    return {
      width: 0,
      height: 0,
      style: {
        width: '220px',
        height: '34px',
        position: 'relative',
        top: '5px',
      },
    };
  }
  if (type == LogoType.Christmas || type == LogoType.MLK) {
    return {
      width: 0,
      height: 0,
      style: {
        width: '220px',
        height: '34px',
        position: 'relative',
        bottom: '5px',
      },
    };
  }
  return {
    width: 220,
    height: 34,
  };
};
