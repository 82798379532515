import { alpha, styled } from '@mui/material/styles';
import { Field, Form } from 'react-final-form';
import { FC, useEffect, useRef } from 'react';
import StyledTextField from '../../atoms/TextField';
import { FromContainer, SearchSubmitButton } from './common';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { useTypingPlaceholder } from './hooks';

const CustomGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: 'unset',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'unset',
    gridTemplateRows: `1fr auto`,
    gap: theme.spacing(2),
  },
}));

const queryInputStyles: (
  isLoading?: boolean,
) => (theme: ThemeMui5) => SystemStyleObject<ThemeMui5> =
  (isLoading) => (theme) => ({
    '&.MuiFormControl-root': {
      margin: 0,
      [theme.breakpoints.down('md')]: {
        margin: '0',
        width: `calc(100% - ${theme.spacing(4)})`,
        justifySelf: 'center',
      },
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
      '& .MuiInputBase-formControl': {
        '&.Mui-error > input': {
          color: theme.palette.secondary.main,
        },
        height: '100%',
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.down('md')]: {
          height: 'unset',
          paddingTop: 0,
        },
        '&.Mui-focused > fieldset': {
          borderColor: theme.palette.secondary.main,
          boxShadow: `0px 0px 0px 3px ${theme.palette.secondary.light}`, // x, y, blur, spread, color
        },
        '& > fieldset': {
          borderRadius: '8px',
          borderWidth: '2px',
          borderColor: theme.palette.secondary.light,
        },
        '& > input': {
          fontWeight: 'bold',
          paddingLeft: theme.spacing(3),
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: theme.typography.body1.fontSize,
          color: theme.palette.text.primary,
          [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
            minHeight: '64px',
          },
          '&::placeholder': {
            color: theme.palette.text.secondary,
          },
        },
      },
    },
    ...(isLoading && {
      '&.MuiFormControl-root .MuiInputBase-formControl.Mui-disabled input.MuiInputBase-input::placeholder':
        {
          color: isLoading
            ? theme.palette.text.primary
            : alpha(theme.palette.text.secondary, 0.5) + ' !important',
          opacity: 1,
          '-webkit-text-fill-color': theme.palette.text.primary,
        },
    }),
  });

const containerStyles: (theme: ThemeMui5) => SystemStyleObject<ThemeMui5> = (
  theme,
) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4, 2),
  },
});

const PLACEHOLDERS = [
  'Searching for your dream car?',
  'Type what you’re looking for!',
];

const SUBMITTING_PLACEHOLDERS = [
  'Find your perfect ride in seconds…',
  'We scan the details; you enjoy the drive…',
  'Car shopping made seamless and stress-free…',
];

type FormInitialValues = {
  query?: string;
};

export type AISearchFormProps = {
  onSubmit: (params: Required<FormInitialValues>) => void;
};

export const AISearchForm: FC<AISearchFormProps> = ({ onSubmit }) => {
  const formInitialValues: FormInitialValues = {
    query: '',
  };

  const placeholder = useTypingPlaceholder({ placeholders: PLACEHOLDERS });

  const submittingPlaceholder = useTypingPlaceholder({
    placeholders: SUBMITTING_PLACEHOLDERS,
    finishDelay: 1000,
    startDelay: 100,
    writeDelay: 50,
    clearDelay: 20,
  });

  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <FromContainer sx={containerStyles}>
      <Form
        initialValues={formInitialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form className='js-ai-search-deals-form' onSubmit={handleSubmit}>
            <CustomGrid>
              <Field name='query'>
                {({ meta, input: { name, onChange, value } }) => (
                  <StyledTextField
                    ref={inputRef}
                    size='small'
                    name={name}
                    label={false}
                    placeholder={
                      submitting ? submittingPlaceholder : placeholder
                    }
                    variant='outlined'
                    fullWidth
                    value={submitting ? '' : value}
                    sx={queryInputStyles(submitting)}
                    onChange={(event) => {
                      onChange(event);
                    }}
                    error={meta.error && meta.touched}
                    disabled={submitting}
                  />
                )}
              </Field>
              <SearchSubmitButton isLoading={submitting} />
            </CustomGrid>
          </form>
        )}
      />
    </FromContainer>
  );
};
