import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { DividerMui5 } from 'components/atoms/Divider';
import { HowItWorksCarousel } from 'components/organisms/HowItWorksCarousel';
import { Subheader } from './components/common';
import { FC } from 'react';
import { SubscriberForm } from 'components/organisms/SubscribeForm';
import { isAuthorizedSelector } from 'containers/deal/recoil/selectors';
import { useRecoilValue } from 'recoil';

export const HowItWorksSection: FC = () => {
  const isAuthorized = useRecoilValue(isAuthorizedSelector);
  return (
    <Box pt={10} pb={8}>
      <Container maxWidth='lg'>
        <Subheader variant='h2' color='textSecondary' align='center'>
          An experience tailored for you
        </Subheader>
        <Typography
          className='js-how-it-works'
          variant='h3'
          align='center'
          gutterBottom
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          Here&apos;s how it works
        </Typography>
        <Typography
          className='js-how-it-works'
          variant='h4'
          align='center'
          gutterBottom
          sx={{ display: { md: 'none', xs: 'block' } }}
        >
          Here&apos;s how it works
        </Typography>
        <DividerMui5 />
      </Container>
      <HowItWorksCarousel />
      {!isAuthorized && (
        <Box
          sx={{
            marginBottom: 10,
          }}
        >
          <SubscriberForm />
        </Box>
      )}
    </Box>
  );
};
