import { styled, Theme as ThemeMui5 } from '@mui/material/styles';
import { FC } from 'react';
import { useScreenSize } from '../../../hooks/useScreenSize';
import Box from '@mui/material/Box';
import { StyledButtonMui5 } from '../../atoms/StyledButton';
import { StyledFontAwesomeIconMui5 } from '../../atoms/FontAwesomeIcon';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import Typography from '@mui/material/Typography';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import {
  Circle,
  Container,
  Svg,
} from '../../organisms/OfferCard/OfferCardMedia';
import { SxProps } from '@mui/system';

export const FromContainer = styled('div', { skipSx: false })(({ theme }) => ({
  zIndex: 1,
  width: '100%',
  maxWidth: '704px',
  borderRadius: 14,
  padding: theme.spacing(2),
  background: theme.palette.common.white,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  [theme.breakpoints.down('md')]: {
    maxWidth: '460px',
    padding: theme.spacing(2),
  },
}));

const searchButtonStyles: (theme: ThemeMui5) => SystemStyleObject<ThemeMui5> = (
  theme,
) => ({
  '& .MuiButton-startIcon': {
    margin: 0,
  },
  '& .MuiButton-iconSizeLarge > *:first-child': {
    fontSize: 28,
  },
  '&.MuiButton-root': {
    [theme.breakpoints.down('sm')]: { height: 80 },
  },
});

const simpleSearchButtonStyles: (
  theme: ThemeMui5,
) => SystemStyleObject<ThemeMui5> = (theme) => ({
  '&.MuiButton-root': {
    padding: 0,
    height: '45.58px',
    width: '45.58px',
    minWidth: 'unset',
    borderRadius: '8px',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      width: '100%',
      paddingY: theme.spacing(0.5),
      marginX: theme.spacing(2),
      borderRadius: '48px',
    },
    '& .MuiButton-icon > svg': {
      width: '16px',
      height: '16px',
    },
  },
});

type SearchSubmitButtonProps = {
  isLoading?: boolean;
};

const loadingSpinnerContainerStyles: SxProps<ThemeMui5> = (t) => ({
  width: t.typography.pxToRem(24),
  height: t.typography.pxToRem(24),
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const loadingSpinnerCircleStyles: SxProps<ThemeMui5> = (t) => ({
  stroke: t.palette.secondary.dark,
  strokeWidth: t.typography.pxToRem(8),
});

const LoadingSpinner: FC = () => (
  <Container isLoading={true} sx={loadingSpinnerContainerStyles}>
    <Svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
      <Circle cx='50' cy='50' r='45' sx={loadingSpinnerCircleStyles} />
    </Svg>
  </Container>
);

export const SearchSubmitButton: FC<SearchSubmitButtonProps> = ({
  isLoading,
}) => {
  const { isExtraSmallScreen, isSmallScreen } = useScreenSize();
  const isMobile = isExtraSmallScreen || isSmallScreen;
  return (
    <Box display='flex' justifyContent='flex-end' alignItems='center'>
      <StyledButtonMui5
        disableElevation
        sx={[searchButtonStyles, simpleSearchButtonStyles]}
        variant='contained'
        color='secondary'
        fullWidth
        type='submit'
        size='medium'
        startIcon={
          <>
            {!isMobile && !isLoading && (
              <StyledFontAwesomeIconMui5 icon={faMagnifyingGlass} />
            )}
            {!isMobile && isLoading && <LoadingSpinner />}
          </>
        }
        disabled={isLoading}
      >
        {isMobile && (
          <Typography
            color='inherit'
            textTransform='capitalize'
            variant='body1'
            component='span'
            sx={(t) => ({ lineHeight: t.typography.pxToRem(45) })}
          >
            {!isLoading && (
              <>
                <b>Search</b>{' '}
                <StyledFontAwesomeIconMui5 icon={faMagnifyingGlass} size='sm' />
              </>
            )}
            {isLoading && (
              <>
                &nbsp;
                <LoadingSpinner />
              </>
            )}
          </Typography>
        )}
      </StyledButtonMui5>
    </Box>
  );
};
