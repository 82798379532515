import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import AppBarMui5 from '@mui/material/AppBar';
import BoxMui5 from '@mui/material/Box';
import IconButtonMui5 from '@mui/material/IconButton';
import {
  createTheme as createThemeMui5,
  styled as styledMui5,
  ThemeProvider,
  ThemeProvider as ThemeProviderMui5,
} from '@mui/material/styles';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import ToolbarMui5 from '@mui/material/Toolbar';
import { SxProps } from '@mui/system';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import {
  TopNavItem,
  TopNavMenuItem,
  TopNavMenuItemProps,
  TopNavMobile,
} from 'components/atoms/TopNav';
import Notifications from 'components/molecules/Notifications';
import { NAVBAR_Z_INDEX } from 'data/constants';
import Image from 'next/image';
import { Fragment, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { userBidsSelector } from 'services/user/recoil';
import { BRAND_NAME, LOGO_TYPE, SITE_LOGO } from 'shared/utils/constants';
import NextLinkMui5 from '../../components/atoms/NextLink';
import { isMobileNavOpenAtom } from '../../recoil/atoms';
import { greyButtonLightTheme, lightThemeDataMui5 } from '../../theme';
import { getSiteLogoStyleProps } from '../../utils/functions';
import { CSSProperties } from '@mui/material/styles/createMixins';

const WrapperMui5 = styledMui5('div')<{ alwaysStuck: boolean }>(
  ({ alwaysStuck }) => `
  z-index: ${NAVBAR_Z_INDEX};
  padding-top: 13px;
  height: ${alwaysStuck ? '65px' : '80px'};`,
);

const generateStickyAppBarStyles = (isSticky: boolean) => {
  return isSticky
    ? `
    max-width: 100%!important;
    width: 100%!important;
    border-radius: 0;
    top: 0;
    position: fixed;`
    : 'padding-right: 0px !important;';
};

const StyledAppBarMui5 = styledMui5(AppBarMui5, {
  shouldForwardProp: (prop) => prop !== 'isSticky',
})<{
  isSticky: boolean;
}>(
  ({ theme, isSticky }) => `
  &.MuiAppBar-root {
    max-width: calc(${theme.breakpoints.values.lg}px - ${theme.spacing(6)});
    background: ${theme.palette.common.white};
    border-radius: 40px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    transition: width .2s, max-width .2s, border-radius .2s;
    ${generateStickyAppBarStyles(isSticky)};
    ${theme.breakpoints.down('lg')} {
      width: calc(100% - ${theme.spacing(6)});
    }
    ${theme.breakpoints.down('sm')} {
      width: calc(100% - ${theme.spacing(4)});
    }
  }`,
);

const AppBarInner = styledMui5('div')(
  ({ theme }) => `
  max-width: calc(${theme.breakpoints.values.lg}px - ${theme.spacing(6)});
  width: 100%;
  padding: ${theme.spacing(0, 3)};
  margin: 0 auto;
  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(0, 2)};
  }`,
);

const StyledToolbarMui5 = styledMui5(ToolbarMui5)(
  ({ theme }) => `
  &.MuiToolbar-root {
    display: flex;
    min-height: 65px;
    justify-content: space-between;
    ${theme.breakpoints.down('md')} {
      justify-content: center;
    }
  }`,
);

const MenuButtonMui5 = styledMui5(IconButtonMui5)(
  ({ theme }) => `
  &.MuiIconButton-root {
    position: absolute;
    left: ${theme.spacing(2)};
    color: ${theme.palette.primary.main};
  }`,
);

const TitleContainerMui5 = styledMui5('span')(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  text-decoration: none;
  lineHeight: 0`,
);

const outlinedMenuItemStyle: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiButton-outlinedInherit': {
    border: `1px solid ${theme.palette.grey[500]}`,
    transition: theme.transitions.create(
      ['color', 'background-color', 'box-shadow', 'border-color'],
      {
        duration: 250,
        easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
        delay: 0,
      },
    ),
  },
});

const COMMON_HIDDEN_PROPS_MIXIN: CSSProperties = {
  display: 'flex',
  boxPack: 'justify',
  boxAlign: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '65px',
};

const HiddenMdDown = styledMui5('div')(({ theme }) => ({
  ...COMMON_HIDDEN_PROPS_MIXIN,
  [theme.breakpoints.down('md')]: {
    display: 'none',
    visibility: 'hidden',
  },
}));

const HiddenSmUp = styledMui5('div')(({ theme }) => ({
  ...COMMON_HIDDEN_PROPS_MIXIN,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
    visibility: 'hidden',
  },
}));

const HiddenMdUp = styledMui5('div')(({ theme }) => ({
  ...COMMON_HIDDEN_PROPS_MIXIN,
  [theme.breakpoints.up('md')]: {
    display: 'none',
    visibility: 'hidden',
  },
}));

const burgerIconButtonStyles: SxProps<ThemeMui5> = () => ({
  '&.MuiIconButton-root': {
    height: '34px',
    width: '34px',
  },
});

const customThemeMui5 = createThemeMui5({
  ...lightThemeDataMui5,
  breakpoints: {
    values: {
      ...lightThemeDataMui5.breakpoints.values,
      sm: 660,
      md: 1207,
    },
  },
});

type TopNavProps = {
  links: TopNavMenuItemProps[];
  actions: TopNavMenuItemProps[];
  alwaysStuck?: boolean;
};

export default function TopNav({
  links,
  actions,
  alwaysStuck,
}: TopNavProps): JSX.Element {
  const bids = useRecoilValueLoadable(userBidsSelector).valueMaybe() || [];
  const [isMobileNavOpen, setIsMobileNavOpen] =
    useRecoilState(isMobileNavOpenAtom);
  const [isSticky, setIsSticky] = useState(alwaysStuck);

  return (
    <ThemeProviderMui5 theme={customThemeMui5}>
      <WrapperMui5 alwaysStuck={alwaysStuck} className='js-navbar'>
        {!alwaysStuck && (
          <InView
            onChange={(inView) => {
              setIsSticky(!inView);
            }}
            fallbackInView={true}
          />
        )}
        <StyledAppBarMui5 isSticky={isSticky} position='fixed'>
          <AppBarInner>
            <StyledToolbarMui5>
              <HiddenMdUp>
                <MenuButtonMui5
                  onClick={() => setIsMobileNavOpen(true)}
                  edge='start'
                  aria-label='menu'
                  sx={burgerIconButtonStyles}
                >
                  <StyledFontAwesomeIconMui5 icon={faBars} size='xs' />
                </MenuButtonMui5>
              </HiddenMdUp>
              <TitleContainerMui5>
                <NextLinkMui5 href='/'>
                  <Image
                    alt={`${BRAND_NAME} logo`}
                    src={SITE_LOGO}
                    {...getSiteLogoStyleProps(LOGO_TYPE)}
                  />
                </NextLinkMui5>
              </TitleContainerMui5>
              <BoxMui5
                sx={{
                  display: { xs: 'none', md: 'block' },
                }}
              >
                {links.map((link, i) => (
                  <Fragment key={link.name + i}>
                    {!link.menuItems && (
                      <TopNavItem
                        href={link.href}
                        onClick={link.onClick}
                        type={link.type}
                        variant={link.variant || 'text'}
                        pathname={link.pathname}
                      >
                        {link.name}
                      </TopNavItem>
                    )}
                  </Fragment>
                ))}
              </BoxMui5>
              <HiddenMdDown>
                <BoxMui5 display='flex'>
                  {bids.length > 0 && <Notifications bids={bids} />}
                  {actions.map((link, i) => (
                    <Fragment key={link.name + i}>
                      <ThemeProvider theme={greyButtonLightTheme}>
                        {!link.menuItems && (
                          <TopNavItem
                            color='grey'
                            href={link.href}
                            onClick={link.onClick}
                            type={link.type}
                            variant={link.variant || 'text'}
                            sx={outlinedMenuItemStyle}
                          >
                            {link.name}
                          </TopNavItem>
                        )}
                      </ThemeProvider>
                      {link.menuItems && (
                        <TopNavMenuItem
                          menuItems={link.menuItems}
                          icon={link.icon}
                          type={link.type}
                          className={link.className}
                        >
                          {link.name}
                        </TopNavMenuItem>
                      )}
                    </Fragment>
                  ))}
                </BoxMui5>
              </HiddenMdDown>
              <HiddenSmUp>
                {bids.length > 0 && <Notifications bids={bids} />}
              </HiddenSmUp>
            </StyledToolbarMui5>
          </AppBarInner>
        </StyledAppBarMui5>
        <TopNavMobile
          links={[...links, ...actions]}
          isOpen={isMobileNavOpen}
          onClose={() => {
            setIsMobileNavOpen(false);
          }}
        />
      </WrapperMui5>
    </ThemeProviderMui5>
  );
}
